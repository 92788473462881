import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 외부기관연동센터
 * @param props
 * @returns
 */
export function ExternalInterWorkingCenterIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.75C6.66848 3.75 6.35054 3.8817 6.11612 4.11612C5.8817 4.35054 5.75 4.66848 5.75 5V20.25H10.5C10.9142 20.25 11.25 20.5858 11.25 21C11.25 21.4142 10.9142 21.75 10.5 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21C2.25 20.5858 2.58579 20.25 3 20.25H4.25V5C4.25 4.27065 4.53973 3.57118 5.05546 3.05546C5.57118 2.53973 6.27065 2.25 7 2.25H17C17.7293 2.25 18.4288 2.53973 18.9445 3.05546C19.4603 3.57118 19.75 4.27065 19.75 5V11.5C19.75 11.9142 19.4142 12.25 19 12.25C18.5858 12.25 18.25 11.9142 18.25 11.5V5C18.25 4.66848 18.1183 4.35054 17.8839 4.11612C17.6495 3.8817 17.3315 3.75 17 3.75H7ZM8.25 8C8.25 7.58579 8.58579 7.25 9 7.25H10C10.4142 7.25 10.75 7.58579 10.75 8C10.75 8.41421 10.4142 8.75 10 8.75H9C8.58579 8.75 8.25 8.41421 8.25 8ZM13.25 8C13.25 7.58579 13.5858 7.25 14 7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75H14C13.5858 8.75 13.25 8.41421 13.25 8ZM8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H10C10.4142 11.25 10.75 11.5858 10.75 12C10.75 12.4142 10.4142 12.75 10 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12ZM13.25 12C13.25 11.5858 13.5858 11.25 14 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H14C13.5858 12.75 13.25 12.4142 13.25 12ZM8.25 16C8.25 15.5858 8.58579 15.25 9 15.25H10C10.4142 15.25 10.75 15.5858 10.75 16C10.75 16.4142 10.4142 16.75 10 16.75H9C8.58579 16.75 8.25 16.4142 8.25 16Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8131 16.1539L17.6303 16.3655C17.3596 16.6789 16.886 16.7135 16.5725 16.4428C16.259 16.172 16.2244 15.6984 16.4952 15.3849L16.6978 15.1504C16.7097 15.1366 16.7221 15.1232 16.735 15.1103C17.286 14.5594 18.0333 14.2499 18.8124 14.25C19.5916 14.2501 20.3388 14.5596 20.8897 15.1106C21.4406 15.6616 21.7501 16.4089 21.75 17.188C21.7499 17.9672 21.4404 18.7144 20.8894 19.2653C20.877 19.2777 20.8642 19.2896 20.851 19.3011L20.6174 19.5041C20.3047 19.7758 19.831 19.7426 19.5593 19.4299C19.2876 19.1172 19.3209 18.6435 19.6335 18.3718L19.8453 18.1878C20.1046 17.9199 20.25 17.5614 20.25 17.1879C20.25 16.8066 20.0986 16.4409 19.8289 16.1712C19.5593 15.9015 19.1936 15.75 18.8123 15.75C18.4392 15.75 18.081 15.895 17.8131 16.1539ZM19.0463 16.9544C19.3392 17.2473 19.3392 17.7222 19.0463 18.0151L17.0463 20.0151C16.7534 20.308 16.2786 20.308 15.9857 20.0151C15.6928 19.7222 15.6928 19.2473 15.9857 18.9544L17.9857 16.9544C18.2786 16.6615 18.7534 16.6615 19.0463 16.9544Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1996 20.8461L17.3824 20.6345C17.6531 20.3211 18.1267 20.2865 18.4402 20.5572C18.7537 20.828 18.7883 21.3016 18.5175 21.6151L18.3149 21.8496C18.303 21.8634 18.2906 21.8768 18.2777 21.8897C17.7267 22.4406 16.9794 22.7501 16.2003 22.75C15.4211 22.7499 14.6739 22.4404 14.123 21.8894C13.5721 21.3384 13.2626 20.5911 13.2627 19.812C13.2628 19.0328 13.5723 18.2856 14.1233 17.7347C14.1357 17.7223 14.1485 17.7104 14.1617 17.6989L14.3953 17.4959C14.708 17.2242 15.1817 17.2574 15.4534 17.5701C15.7251 17.8828 15.6918 18.3565 15.3792 18.6282L15.1674 18.8122C14.9081 19.0801 14.7627 19.4386 14.7627 19.8121C14.7627 20.1934 14.9141 20.5591 15.1838 20.8288C15.4534 21.0985 15.8191 21.25 16.2004 21.25C16.5735 21.25 16.9317 21.105 17.1996 20.8461Z"
        fill="black"
      />
    </IconContainer>
  );
}

export default ExternalInterWorkingCenterIcon;
