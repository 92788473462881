export { default as AdditionIcon } from './Addition';
export { default as AddStorageIcon } from './AddStorage';
export { default as AirplaneIcon } from './airplane';
export { default as ArrowLeftCircleIcon } from './arrow-left-circle';
export { default as ArrowRightIcon } from './arrow-right';
export { default as ArrowRightCircleIcon } from './arrow-right-circle';
export { default as ArrowLeftIcon } from './ArrowLeft';
export { default as ArrowUpSquareIcon } from './ArrowUpSquare';
export { default as Bars2SlashIcon } from './bars-two-slash';
export { default as BellIcon } from './Bell';
export { default as BoldIcon } from './Bold';
export { default as BookOpenIcon } from './BookOpen';
export { default as BoxIcon } from './box';
export { default as CalendarCheckIcon } from './calendar-check';
export { default as CalendarIcon } from './Calendar';
export { default as ChatbotIcon } from './Chatbot';
export { default as ChevronDownIcon } from './ChevronDown';
export { default as ChevronLeftIcon } from './ChevronLeft';
export { default as ChevronRightIcon } from './ChevronRight';
export { default as ChevronUpIcon } from './ChevronUp';
export { default as ClearIcon } from './Clear';
export { default as ClipIcon } from './Clip';
export { default as ClockIcon } from './Clock';
export { default as CloseIcon } from './Close';
export { default as CloudDownloadIcon } from './cloud-download';
export { default as CogIcon } from './Cog';
export { default as CommentIcon } from './Comment';
export { default as CopyIcon } from './copy';
export { default as CubeIcon } from './Cube';
export { default as CustomerPortalIcon } from './customer-portal';
export { default as DatePickerCalendarIcon } from './date-picker-calendar';
export { default as DeleteIcon } from './Delete';
export { default as DeleteAttachFile } from './DeleteAttachFile';
export { default as DeviceMobileSolidIcon } from './device-mobile-solid';
export { default as DiabledHamburgerMenuIcon } from './DiabledHamburgerMenu';
export { default as DocumentIcon } from './Document';
export { default as DocumentPencil } from './DocumentPencil';
export { default as DownloadIcon } from './download';
export { default as DragIcon } from './Drag';
export { default as EarthIcon } from './Earth';
export { default as EllipsisHorizontalIcon } from './EllipsisHorizontal';
export { default as EllipsisVerticalIcon } from './EllipsisVertical';
export { default as ExclamationCircleIcon } from './ExclamationCircle';
export { default as ExternalInterWorkingCenterIcon } from './external-interworking-center';
export { default as EyeIcon } from './eye';
export { default as FilterIcon } from './Filter';
export { default as FolderRefundIcon } from './FolderRefund';
export { default as FolderUpIcon } from './FolderUp';
export { default as GearSettingsIcon } from './GearSettings';
export { default as HamburgerMenuIcon } from './HamburgerMenu';
export { default as HelpIcon } from './Help';
export { default as HomeIcon } from './Home';
export { default as ImageDeleteIcon } from './ImageDelete';
export { default as ImageSelectIcon } from './ImageSelect';
export { default as InboxIcon } from './Inbox';
export { default as InfoIcon } from './Info';
export { default as InputInvisibleIcon } from './InputInvisible';
export { default as InputVisibleIcon } from './InputVisible';
export { default as ItalicIcon } from './Italic';
export { default as KeyIcon } from './Key';
export { default as LaptopIcon } from './laptop';
export { default as LinkIcon } from './link';
export { default as LinkPlusIcon } from './LinkPlus';
export { default as ListBullet, default as ListBulletIcon } from './ListBullet';
export { default as LockIcon } from './lock';
export { default as LogOutIcon } from './LogOut';
export { default as LogoutRightIcon } from './logout-right';
export { default as MagnifyingIcon } from './Magnifying';
export { default as MailIcon } from './Mail';
export { default as MailSolidIcon } from './mail-solid';
export { default as MegaphoneIcon } from './Megaphone';
export { default as MenuIcon } from './Menu';
export { default as MinusIcon } from './minus';
export { default as NumberedListIcon } from './NumberedList';
export { default as OrgChartIcon } from './OrgChart';
export { default as PlusIcon } from './Plus';
export { default as PowerOnOffIcon } from './PowerOnOff';
export { default as QuestionCircleIcon } from './QuestionCircle';
export { default as RectDotsIcon } from './RectDots';
export { default as RefreshIcon } from './Refresh';
export { default as RestoreIcon } from './Restore';
export { default as SearchIcon } from './Search';
export { default as SearchClearIcon } from './SearchClear';
export { default as SettingsIcon } from './Settings';
export { default as ShieldCheckIcon } from './shield-check';
export { default as SpeakerIcon } from './speaker';
export { default as StarIcon } from './Star';
export { default as StarSolidIcon } from './star-solid';
export { default as StorageSettingsIcon } from './StorageSettings';
export { default as StrikeIcon } from './Strike';
export { default as TrashCanIcon } from './TrashCan';
export { default as UmbrellaIcon } from './umbrella';
export { default as UserMasterIcon } from './user-master';
export { default as UserMinusIcon } from './user-minus';
export { default as UserCircleIcon } from './UserCircle';
export { default as XMarkBorderIcon } from './x-mark-border';
export { default as XMarkIcon } from './XMark';
export { default as FoldingIcon } from './folding';

// deprecated
export { default as MoreIcon } from './More';
