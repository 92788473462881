import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 고객포털
 * @param props
 * @returns
 */
export function CustomerPortalIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M18.5425 20.625C18.1225 20.625 17.7198 20.4582 17.4229 20.1613C17.1259 19.8644 16.9591 19.4616 16.9591 19.0417C16.9591 18.6218 17.1259 18.2191 17.4229 17.9221C17.7198 17.6252 18.1225 17.4584 18.5425 17.4584M18.5425 20.625C18.9624 20.625 19.3651 20.4582 19.662 20.1613C19.959 19.8644 20.1258 19.4616 20.1258 19.0417C20.1258 18.6218 19.959 18.2191 19.662 17.9221C19.3651 17.6252 18.9624 17.4584 18.5425 17.4584M18.5425 20.625V21.8125M18.5425 17.4584V16.2709M20.942 17.6563L19.9136 18.25M17.1721 19.8334L16.1429 20.4271M16.1429 17.6563L17.1721 18.25M19.9136 19.8334L20.9428 20.4271"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4091 4.40901C10.831 3.98705 11.4033 3.75 12 3.75C12.5968 3.75 13.1691 3.98705 13.591 4.40901C14.013 4.83097 14.25 5.40326 14.25 6V7.25H9.75005V6C9.75005 5.40326 9.9871 4.83097 10.4091 4.40901ZM8.25005 7.25V6C8.25005 5.00544 8.64514 4.05161 9.3484 3.34835C10.0517 2.64509 11.0055 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.355 4.05161 15.75 5.00544 15.75 6V7.25H16.1429H17.67C18.0665 7.24997 18.4583 7.33566 18.8186 7.50121C19.1788 7.66678 19.4991 7.90829 19.7573 8.2092C20.0155 8.51012 20.2056 8.86331 20.3145 9.24455C20.4234 9.62581 20.4486 10.0262 20.3883 10.4181L19.8883 13.6661C19.8253 14.0755 19.4423 14.3563 19.0329 14.2933C18.6235 14.2302 18.3427 13.8473 18.4058 13.4379L18.9058 10.1899C18.9331 10.0118 18.9217 9.82988 18.8722 9.65662C18.8227 9.48332 18.7363 9.32278 18.6189 9.186C18.5015 9.04922 18.356 8.93944 18.1922 8.86419C18.0285 8.78893 17.8503 8.74998 17.67 8.75H16.1429H15.75V11C15.75 11.4142 15.4143 11.75 15 11.75C14.5858 11.75 14.25 11.4142 14.25 11V8.75H9.75005V11C9.75005 11.4142 9.41426 11.75 9.00005 11.75C8.58583 11.75 8.25005 11.4142 8.25005 11V8.75H6.33594H6.33104C6.15081 8.74998 5.97261 8.78893 5.80885 8.86419C5.64508 8.93944 5.49953 9.04922 5.38216 9.186C5.2648 9.32278 5.1784 9.48332 5.12889 9.65662C5.07939 9.82987 5.06793 10.0118 5.0953 10.1899L6.3503 18.3419C6.432 18.8731 6.70116 19.3576 7.10904 19.7076C7.51692 20.0576 8.0366 20.25 8.57404 20.25H11.5C11.9143 20.25 12.25 20.5858 12.25 21C12.25 21.4142 11.9143 21.75 11.5 21.75H8.57404C7.67829 21.75 6.8121 21.4293 6.13229 20.846C5.4525 20.2627 5.00395 19.4554 4.86777 18.5701L3.61277 10.4181C3.5525 10.0262 3.57767 9.62581 3.6866 9.24455C3.79552 8.8633 3.9856 8.51012 4.2438 8.2092C4.502 7.90829 4.82223 7.66678 5.18251 7.50121C5.54277 7.33566 5.93465 7.24997 6.33113 7.25L6.33594 7.25376V7.25H8.25005Z"
        fill="black"
      />
    </IconContainer>
  );
}

export default CustomerPortalIcon;
